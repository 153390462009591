<template>
  <div class="scroll-x-box">
    <div
      class="mind-class-content clearfix"
      :style="'width:' + scrollXWidth + 'px;' + 'left:' + left + 'px'"
    >
      <div
        v-for="(each, index) in data"
        :key="index"
        @click="clickCreated(each, index)"
        class="modal-box-children"
      >
        <div class="gray-box"></div>
        <div
          v-if="
            !(
              each.layoutType == 4 &&
              each.mindType == 1 &&
              each.isEmpty == true
            )
          "
          class=""
          :style="
            'width: 160px;height: 140px;background:url(' +
            each.icon +
            ') no-repeat;background-size: 160px 140px;background-position:center;overflow:hidden;'
          "
        ></div>
        <div
          v-else
          class=""
          :style="
            'width: 160px;height: 140px;background:url(' +
            each.icon +
            ') no-repeat;background-size: cover;background-position:center;overflow:hidden;'
          "
        >
          <div class="empty-text">
            {{ getString(strings.My_Mind_Blank_Mind_Map) }}
          </div>

          <div  
            class="item-explain" >

            请问请问请问
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showBoxWidth < scrollXWidth"
      @click="scrollRight"
      class="left-scroll-button"
    >
      <a-icon type="left" />
    </div>
    <div
      v-if="showBoxWidth < scrollXWidth"
      @click="scrollLeft"
      class="right-scroll-button"
    >
      <a-icon type="right" />
    </div>
    <a-modal
      class="report-member-model"
      :centered="true"
      v-model="storageIsMeberModelAccout"
      :title="getString(strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="storageIsMeberCancelHandleOk"
      @ok="storageIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Insufficient_Storage_Space) }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
// each.icon.indexOf('left_right_layout_empty') < 0

import CreatMindItmeData from "../../../views/uidata/CreatMindItmeData";

import { mapMutations } from "vuex";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import { postMindmapNewByType } from "../../../common/netWork/mind_map_api";
import { postMindmapListDir } from "../../../common/netWork/networkcache";
import ConfigurationItem from "../../../assets/js/ConfigurationItem.js";
const { numberOfMapsTourist } = ConfigurationItem;
import { getJwt } from "../../../common/netWork/base";
import NewLocalMindParameter from "../../../core/core/dataformat/fileImport/NewLocalMindParameter";
import NewXJMindMap from "../../../core/core/dataformat/fileImport/NewXJMindMap";
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
// const {ipcRenderer} = window.require('electron')
export default {
  name: "CreatedFileScroll",
  props: {
    data: {
      type: Array,
    },
    folder: {
      type: Object,
    },
    storageCloud: {
      type: Boolean,
    },
  },
  data() {
    return {
      left: 0,
      fullWidth: document.documentElement.clientWidth,
      showBoxWidth: document.documentElement.clientWidth - 215,
      clickButton: true,
      src: require("../../../../static/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_5.jpg"),
      src1: "../../../../static/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_5.jpg",
      befroeString: "../",
      urlPrefix: "../../../static/img/mapIcon/res/mipmap-xxhdpi/", //地址前缀
      strings,
      storageIsMeberModelAccout: false, //存储空间不足提示框
    };
  },
  created() {},
  mounted() {
    this.bus.$on("fullWidth", (msg) => {
      this.fullWidth = msg;
    });
  },
  methods: {
    ...mapMutations(["isShowShoppingModel", "refreshMyMindList"]),
    //语言包
    getString(i) {
      return getString(i);
    },
    storageIsMeberCancelHandleOk() {
      this.storageIsMeberModelAccout = false;
    },
    storageIsMeberDeleteHandleOk() {
      this.storageIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    clickCreated(item, index) {
      let parentEntityId = this.folder.id; //  父文件夹id,默认为空
      let type = item.mindType; //  导图类型
      let mindDisplayType = item.mindDisplayType; //  导图模式
      let nodeLayoutType = item.layoutType; //  导图布局
      let lineLayout = item.lineLayout; //  线条类型
      let isEmpty = item.isEmpty; //  是否是空导图
      let styleIndex = item.styleIndex; //  styleIndex
      let data = {
        parentEntityId,
        type,
        mindDisplayType,
        nodeLayoutType,
        lineLayout,
        isEmpty,
        styleIndex,
      };
      // folder
      if (!this.storageCloud) { //只创建到本地
        var name = item.name;
        if ((typeof name) == "number") {
            try {
              name = getString(name)
            } catch (error) {
              name = name + "";
            }
        }
        let newLocalMindParameter = new NewLocalMindParameter()
        newLocalMindParameter.parentEntityId = parentEntityId
        newLocalMindParameter.type = type
        newLocalMindParameter.mindDisplayType = mindDisplayType
        newLocalMindParameter.nodeLayoutType = nodeLayoutType
        newLocalMindParameter.lineLayout = lineLayout
        newLocalMindParameter.isEmpty = isEmpty
        newLocalMindParameter.styleIndex = styleIndex
        let parameter = newLocalMindParameter.getJson()
        new NewXJMindMap(name, type, this.$router.appPath, parameter).createLocalMindMap().then((creearData) => {

        })
        return
      }
      // 未登录导图数量超过1个弹出登陆，否则不能创建
      postMindmapListDir(
        { parentId: "" },
        (res) => {
          localStorage.setItem("mapsNumber", res.entities.length);
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
      const mapsNumber = localStorage.getItem("mapsNumber") || 0;
      if (mapsNumber >= numberOfMapsTourist && !getJwt("jwt")) {
        this.$store.state.showLogin = true;
        return;
      }
      if (this.$tools.isElectron()) {
        postMindmapNewByType(
          data,
          (res) => {
            ipcRenderer.send(
              "openCalendarWindow",
              "/mindmap?id=" + res.mindMapId
            );
            this.refreshMyMindList();
          },
          (error) => {
            if (error.code == 51) {
              this.storageIsMeberModelAccout = true;
            } else {
              this.$message.error(error.desc);
            }
          }
        );
      } else {
        postMindmapNewByType(
          data,
          (res) => {
            const routerData = this.$router.resolve({
              path: "/mindmap",
              query: {
                id: res.mindMapId,
              },
            });
            this.createATagJump(routerData.href);
            this.refreshMyMindList();
          },
          (error) => {
            if (error.code == 51) {
              this.storageIsMeberModelAccout = true;
            } else {
              this.$message.error(error.desc);
            }
          }
        );
      }
    },
    createATagJump(url) {
      const a = document.createElement("a"); //创建a标签浏览器不会阻止弹出窗口
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 5000);
    },
    scrollLeft() {
      if (this.clickButton == false) {
        return;
      }
      this.clickButton = false;
      let count = 0;
      let timer = setInterval(() => {
        if (this.scrollXWidth - this.showBoxWidth < -this.left) {
          this.left = -(this.scrollXWidth - this.showBoxWidth);
          this.clickButton = true;
          clearInterval(timer);
        } else {
          this.left -= 13.5;
        }
        count++;
        if (count == 9) {
          clearInterval(timer);
          this.clickButton = true;
        }
      }, 50);
    },
    scrollRight() {
      if (this.clickButton == false) {
        return;
      }
      this.clickButton = false;
      let count = 0;
      let timer = setInterval(() => {
        if (0 <= this.left) {
          this.left = 0;
          this.clickButton = true;
          clearInterval(timer);
        } else {
          this.left += 13.5;
        }
        count++;
        if (count == 9) {
          clearInterval(timer);
          this.clickButton = true;
        }
      }, 50);
    },
  },
  watch: {
    fullWidth: function (val) {
      this.showBoxWidth = val - 215;
    },
  },
  computed: {
    scrollXWidth: function () {
      return this.data.length * 190;
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-x-box {
  position: relative;
  height: 160px;
  overflow: hidden;
}
.left-scroll-button,
.right-scroll-button {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #666;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 1px 2px #999;
}
.left-scroll-button {
  left: 0;
  transform: translate(0, -50%);
}
.right-scroll-button {
  right: 0;
  transform: translate(0, -50%);
}
.mind-class-content {
  position: absolute;
  /* width: 600px;
    height: 135px; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* flex-wrap:nowrap; */
}
.modal-box-children {
  float: left;
  /* width: 120px;
  height: 105px; */
  width: 160px;
  height: 140px;
  margin: 10px 0px 10px 30px;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 3px 0px #d2d2d2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .empty-text {
    margin: 0 auto;
    margin-top: 90px;
    font-size: 16px;
    text-align: center;
  }
  .gray-box {
    display: none;
  }
}
.modal-box-children:hover {
  box-shadow: 0 0 12px #ccc;
}

.mind-class-content > i {
  width: 120px;
  margin-right: 20px;
  margin-left: 20px;
}
.item-explain {
  position: absolute;
  display: block;
  left: 0px;
  bottom: 0px;
  text-align: center;
  width: 200px;
  height: 20px;
  background-color: #1a1a1a73;
  border-radius: 2px;
}
</style>